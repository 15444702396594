















// Core
import { Component, Prop, Vue } from 'vue-property-decorator'

// Store
import { documentsNamespace } from '@/store/documents'

// Interfaces
import { IDocumentTypes } from '@/store/documents/Interface'
import { Orders } from '@/store/team/interfaces'

@Component({
  name: 'Order',
})
export default class OrderComponent extends Vue {
  @Prop(Object) private order!: Orders

  @documentsNamespace.State('documentTypes')
  private documentTypes!: IDocumentTypes[]

  private get documentType(): string {
    if (this.order.order.documentTypeId) {
      const [type] = this.documentTypes.filter(t => t.id === this.order.order.documentTypeId)

      return type.name
    } else {
      return ''
    }
  }

  private handleClick() {
    window.open(this.order.order.orderUrl as string, '_blank')
  }
}
